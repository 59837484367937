import React, { Component } from "react";
import emailjs from '@emailjs/browser';
import "./Contact.css";

class Form extends Component {
  // Setting the component's initial state
  state = {
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  };

  handleInputChange = event => {
    // Getting the value and name of the input which triggered the change
    const value = event.target.value;
    const name = event.target.name;

    // Updating the input's state
    this.setState({
      [name]: value
    });
  };

  handleFormSubmit = event => {
    // Preventing the default behavior of the form submit (which is to refresh the page)
    event.preventDefault();

   
    if(!(this.state.lastName && this.state.firstName && this.state.email && this.state.subject)) {alert("Please complete all fields!");}

    // Alert the user their first and last name, clear `this.state.firstName` and `this.state.lastName`, clearing the inputs
    alert(`Submitted with your message -  ${this.state.firstName} ${this.state.lastName}\n ${this.state.email}\n ${this.state.subject}\n ${this.state.message} `);
  
      const templateParams = {
        from_name: this.state.firstName+ " " + this.state.lastName,
        from_email: this.state.email,
        from_subject: this.state.subject,
        from_message: this.state.message,
      };
  
        emailjs.send('service_uyracms', 'template_ynz9v7g', templateParams, 'NyspTPaNNuHG_EVwK')
          .then(function (response) {
            alert("Email Sent.....😍")
          }, function (error) {
            alert("Email send error.....💩")
          });
   

    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      message:"",
    });

  };

  render() {
    // each input has a `value`, `name`, and `onChange` prop
    return (
      <>
      <h2 className="contact-title">Contact Me</h2>
      <div className="container contact-page-container">

        <iframe className="resume" title="resume.pdf" src="https://drive.google.com/file/d/1K39K6nI1KEONo9TPsR6lm0LQQ2UspX-k/preview?usp=share_link"></iframe>
      
      <div className="container form-container">
        
        <p className="hello">
          Hello {this.state.firstName} {this.state.lastName}
        </p>
        <form className="form">
          <input
            value={this.state.firstName}
            name="firstName"
            onChange={this.handleInputChange}
            type="text"
            placeholder="First Name"
          />
          <input
            value={this.state.lastName}
            name="lastName"
            onChange={this.handleInputChange}
            type="text"
            placeholder="Last Name"
          />
          <input
            value={this.state.email}
            name="email"
            onChange={this.handleInputChange}
            type="text"
            placeholder="Email Address"
          />
          <input
            value={this.state.subject}
            name="subject"
            onChange={this.handleInputChange}
            type="text"
            placeholder="Subject info"
          />
          <textarea className="message"
            value={this.state.message}
            name="message"
            onChange={this.handleInputChange}
            type="text"
            placeholder="Please type your message here"
          />
          <button className="submit" onClick={this.handleFormSubmit}>Submit</button>
        </form>
        <div className="contact d-none d-lg-block" id="contact">
            <h3>Contact Details</h3>
            <p>Any contact relating to individual repositories 
              should be made via Github issues on a per repo basis for tracking purposes.</p>
            <p>
              <a className="LinkedIin-user" href="https://www.linkedin.com/in/ben-shingleton-profile-devops-coder-techy" target="blank">LinkedIn</a></p>
             <p> <a className="github-user" href="https://github.com/benjistealth/" target="blank">Github</a></p>
            <p> <a className="email" href="mailto:bstealth45@gmail.com">Send me an email</a></p>           
          </div>
          
          </div>

      </div>
      </>
    );
  }
}

export default Form;
