import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/pages/Home/Home';
import About from './components/pages/AboutMore/About';
import Contact from './components/pages/Contact/Contact';
import More from './components/pages/AboutMore/More';
import ProjectGallery from './components/pages/ProjectPages/ProjectGallery';
import ProjPage from './components/pages/ProjectPages/ProjPage';


function App() {

  return (
    <>
    <Header className="container"/> 
    <Router>
      <div>
        {/* Wrap Route elements in a Routes component */}
        <Routes>
          {/* Define routes using the Route component to render different page components at different paths */}
          {/* Define a default route that will render the Home component */}
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/More" element={<More />} />
          <Route path="/ProjectGallery" element={<ProjectGallery />} />
          <Route path="/ProjPage" element={<ProjPage />} />
          {/* Define a route that will have descendant routes */}
          {/* <Route path="contact/*" element={<Contact />} /> */}
        </Routes>
      </div>
      <Footer></Footer>
    </Router>
     
    </>
  );
}

export default App;