import React from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import "./ProjectPages.css";
import Wrapper from '../../Wrapper/Wrapper';

function ProjPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const project = location.state;

  const GoBack = () => {
    navigate(-1);
  }

  return (
    <Wrapper>
      <div>
        <h1>{project.name}</h1>
        <h3>Click the image below to open the deployed application</h3>
        {/* back button */}
        <button className="btn btn-back" onClick={GoBack}></button>
        <div className="img-container">
          <a href={project.deployedUrl}><img className="rounded mx-auto d-block project-img" alt={project.alt} src={project.image} /></a>
        </div>
        <div className="content">
          <ul>
            <li>
              <strong>Project Name :</strong> {project.name}
            </li>
            <li>
              <strong>Project Brief Description :</strong> {project.briefDescription}
            </li>
            <li>
              <strong>Click to Github Repo :</strong> <a href={project.repoURL}><img className="d-block github-img" alt={"Github logo"} src={"https://raw.githubusercontent.com/benjistealth/benji_password_generator/main/assets/images/Github.png"} /></a>
            </li>
          </ul>
          <p className="description d-none d-lg-block">
            {project.longDescription}
          </p>
        </div>
        <button className="btn btn-back" onClick={GoBack}></button>
      </div>
    </Wrapper>
  );
}

export default ProjPage;
