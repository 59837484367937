import projects from '../../projects.json';
import ProjCard from '../../ProjCard/ProjCard';
import Wrapper from '../../Wrapper/Wrapper';
// used to trigger page changes via router from JS easily :-)
import { useNavigate } from "react-router-dom";


function ProjectGallery() {

    const navigate = useNavigate();
    
    function GoToProject(page, id, name, repoURL, deployedUrl, image, briefDescription, longDescription) {
            
        navigate(page, {
            state:{
                id,
                name,
                repoURL,
                deployedUrl,
                image,
                briefDescription,
                longDescription,
            }
        });
        //when the project is selected, the chosen project page will be shown 
        //where you can click through to the project deployment or repo
        //the project page will have some text talking about the project
    }

    return (
        <div className="container">
            <Wrapper>
            {projects.map(f => <ProjCard 
                    key={f.id}
                    id={f.id}
                    name={f.name}
                    repoURL={f.repoURL}
                    deployedUrl={f.deployedUrl}
                    image={f.image}
                    briefDescription={f.briefDescription}
                    longDescription={f.longDescription}
                    selectFunction={() => { GoToProject(f.page, f.id, f.name, f.repoURL,
                     f.deployedUrl, f.image, f.briefDescription, f.longDescription) }} />)} {/* make this a select function above and go to a new page for each project?*/}
                    {/* {project} */}
            </Wrapper>
        </div>
    );
}

export default ProjectGallery;
